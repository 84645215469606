import { createSlice } from "@reduxjs/toolkit"
import { listPredictionVideoMiddleWare, videoCategoryMiddleWare } from "./videoMiddleware"
import { appendKeyValue } from "../../../utils/util";


const initialState = {
    isLoading : false ,
    data : [],
    videoCaterogyList : []
}

const predictionVideoReducer = createSlice({
    name : "prediction_video",
    initialState,
    reducers:{},
    extraReducers : (builder) => {
        builder.addCase(listPredictionVideoMiddleWare.pending,(state) => {
            state.isLoading = true;
            state.data = []
        });
        builder.addCase(listPredictionVideoMiddleWare.fulfilled,(state,{payload}) => {
            state.isLoading = false;
            state.data = payload
        });
        builder.addCase(listPredictionVideoMiddleWare.rejected,(state) => {
            state.isLoading = false;
            state.data = []
        });
        builder.addCase(videoCategoryMiddleWare.pending,(state) => {
            state.isLoading = false;
            state.videoCaterogyList = []
        });
        builder.addCase(videoCategoryMiddleWare.fulfilled,(state,{payload}) => {
            state.isLoading = false;
            state.videoCaterogyList = appendKeyValue(payload.data)
        });
        builder.addCase(videoCategoryMiddleWare.rejected,(state) => {
            state.isLoading = false;
            state.videoCaterogyList = []
        });
    }
})


export const predictionVideoReducers = predictionVideoReducer.reducer