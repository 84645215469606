import { Modal, Spin } from "antd";

const Loader = () => {
  return (
    <Modal open centered footer={null} closable={false} maskClosable={false}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Spin size="large" />
      </div>
    </Modal>
  );
};

export default Loader;
