import { createSlice } from "@reduxjs/toolkit";
import { getProfileDetailsMiddleWare } from "./profileMiddleware";

const initialState = {
  profileDetails: null,
  isLoading: false,
};

const profileReducer = createSlice({
  name: "edit-profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(getProfileDetailsMiddleWare.fulfilled, (state, action) => {
    //   state.profileDetails = action.payload;
    // });
    builder.addCase(getProfileDetailsMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getProfileDetailsMiddleWare.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.profileDetails = payload;
      }
    );
    builder.addCase(getProfileDetailsMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const profileReducers = profileReducer.reducer;
