import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  astroGenieApi,
  bindGameApi,
  bookOfLoveApi,
  colorTherapyApi,
  crystalBallApi,
  fortuneCookiesApi,
} from "../../../routes/apiRoutes";
import {
  GET_ASTRO_GENIE,
  GET_BIND_GAMEA,
  GET_BOOK_OF_LOVE,
  GET_COLOR_THERAPY,
  GET_CRYSTAL_BALL,
  GET_FORTUNE_COOKIES,
} from "../../../redux/actions";

export const getBindGameMiddleWare = createAsyncThunk(
  GET_BIND_GAMEA,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(bindGameApi);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFortuneCookiesMiddleWare = createAsyncThunk(
  GET_FORTUNE_COOKIES,
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(fortuneCookiesApi(id));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBookOfLoveMiddleWare = createAsyncThunk(
  GET_BOOK_OF_LOVE,
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(bookOfLoveApi(id));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAstroGenieMiddleWare = createAsyncThunk(
  GET_ASTRO_GENIE,
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(astroGenieApi(id));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getColorTherapyMiddleWare = createAsyncThunk(
  GET_COLOR_THERAPY,
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(colorTherapyApi(id));
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getCrystalBallMiddleWare = createAsyncThunk(
  GET_CRYSTAL_BALL,
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(crystalBallApi(id));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
