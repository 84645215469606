import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  addGuestQsApi,
  getCityApi,
  questionListApi,
  questionTopicApi,
} from "../../../routes/apiRoutes";
import {
  QUESTION_TOPIC,
  QUESTION_LIST,
  ADD_GUEST_QUESTION,
  GET_CITY,
} from "../../../redux/actions";
import { toast } from "react-toastify";

export const questionTopicMiddleWare = createAsyncThunk(
  QUESTION_TOPIC,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(questionTopicApi);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const questionListMiddleWare = createAsyncThunk(
  QUESTION_LIST,
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${questionListApi}${id}`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const submitGuestQuestionMiddleWare = createAsyncThunk(
  ADD_GUEST_QUESTION,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(addGuestQsApi, payload);
      return data.data;
    } catch (error) {
      const errorText = error.response.data.alert;
      toast(errorText);
      return rejectWithValue(error);
    }
  }
);

export const getCityMiddleWare = createAsyncThunk(
  GET_CITY,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(getCityApi);
      return data.data.map((item) => ({
        ...item,
        key: item._id,
        label: item.cityName,
      }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
