import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  fileUploadApi,
  getNotificationApi,
  getWalletListApi,
  phonepeStatusCheckApi,
  profileDetailsApi,
  saveEmailIDApi,
  saveMobileNoApi,
  updateEmailIDApi,
  updateMobileNoApi,
  userDeleteApi,
  verifyEmailIDApi,
  verifyMobileNoApi,
} from "../../../../routes/apiRoutes";
import {
  FILE_UPLOAD,
  GET_NOTIFICATION,
  GET_PROFILE_DETAILS,
  GET_WALLET,
  PHONEPE_STATUS_CHECK,
  SAVE_EMAIL_ID,
  SAVE_MOBILE_NUMBER,
  UPDATE_EMAIL_ID,
  UPDATE_MOBILE_NUMBER,
  UPDATE_PROFILE_DETAILS,
  USER_DELETE,
  VERIFY_EMAIL_ID,
  VERIFY_MOBILE_NUMBER,
} from "../../../../redux/actions";

export const getProfileDetailsMiddleWare = createAsyncThunk(
  GET_PROFILE_DETAILS,
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${profileDetailsApi}/${id}`);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProfileDetailsMiddleWare = createAsyncThunk(
  UPDATE_PROFILE_DETAILS,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(profileDetailsApi, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateMobileNumberMiddleWare = createAsyncThunk(
  UPDATE_MOBILE_NUMBER,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(updateMobileNoApi, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const verifyMobileNumberMiddleWare = createAsyncThunk(
  VERIFY_MOBILE_NUMBER,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(verifyMobileNoApi, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateEmailIdMiddleWare = createAsyncThunk(
  UPDATE_EMAIL_ID,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(updateEmailIDApi, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const verifyEmailIdMiddleWare = createAsyncThunk(
  VERIFY_EMAIL_ID,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(verifyEmailIDApi, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveEmailIdMiddleWare = createAsyncThunk(
  SAVE_EMAIL_ID,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(saveEmailIDApi, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const saveMobileNumberMiddleWare = createAsyncThunk(
  SAVE_MOBILE_NUMBER,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(saveMobileNoApi, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fileUploadMiddleWare = createAsyncThunk(
  FILE_UPLOAD,
  async ({ formData }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(fileUploadApi, formData);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNotificationMiddleWare = createAsyncThunk(
  GET_NOTIFICATION,
  async ({ userId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(getNotificationApi(userId));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getWalletMiddleWare = createAsyncThunk(
  GET_WALLET,
  async (_a, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(getWalletListApi);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userDeleteMiddleWare = createAsyncThunk(
  USER_DELETE,
  async ({ userId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(userDeleteApi(userId));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const phonepeStatusCheckMiddleWare = createAsyncThunk(
  PHONEPE_STATUS_CHECK,
  async ({ merchantId, merchantTransactionId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(phonepeStatusCheckApi, {
        merchantId,
        merchantTransactionId,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
