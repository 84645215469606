import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import { useInterceptors } from "./utils/interceptors";

const AppProvider = () => {
  useInterceptors();
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default AppProvider;
