import dayjs from "dayjs";

export const scrollTop = (id) => {
  if (id) {
    const element = document?.getElementById(id);
    window?.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  } else {
    window?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
};

export const getUserId = () => {
  const user_details = JSON.parse(localStorage.getItem("user"));
  return user_details ? user_details?.data?.id : null;
};
export const getUserDetails = () => {
  const user_details = JSON.parse(localStorage.getItem("user"));
  return user_details ? user_details?.data : null;
};

export function isValidImageUrl(url) {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
  const urlParts = url?.split(".");
  const extension = urlParts && urlParts[urlParts?.length - 1].toLowerCase();
  return imageExtensions.includes(extension);
}

export function isEmpty(value) {
  return value ? false : true;
}

export function isEmptyArr(value) {
  return value && Array.isArray(value) && value?.length ? false : true;
}

export function appendKeyValue(list) {
  if (!isEmptyArr(list)) {
    const appendValue = list.map((D) => ({ key: D._id, label: D.dataName }));
    return appendValue;
  }
  return [];
}

export function getMonthName(monthNumber) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[monthNumber - 1];
}

export function formatZodiacSigns(zodiacData) {
  return zodiacData.map((sign) => {
    const startMonthName = getMonthName(sign.monthStart);
    const endMonthName = getMonthName(sign.monthEnd);
    const label = `${sign.dataName} (${startMonthName} ${sign.dateStart} – ${endMonthName} ${sign.dateEnd})`;
    const dateRange = `${startMonthName} ${sign.dateStart} – ${endMonthName} ${sign.dateEnd}`;

    return {
      ...sign,
      key: sign._id,
      label: label,
      value: sign._id,
      dateRange: dateRange,
    };
  });
}

export function getDateWithOffset(offset) {
  const targetDate = dayjs().add(offset, "days");
  return targetDate.format("dddd, MMMM D");
}

export const handleDeviceDetection = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
    userAgent
  );
  const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(
    userAgent
  );

  if (isMobile) {
    return true;
    // setDevice("Mobile");
  } else if (isTablet) {
    return false;
    // setDevice("Tablet");
  } else {
    return false;
    // setDevice("Desktop");
  }
};

export const areAllKeysEmpty = (obj) => {
  return Object.values(obj).some((v) => !v);
};

export const validateFileSize = (size) => {
  // Get file size in bytes
  const fileSize = size;

  // Convert bytes to megabytes
  const fileSizeInMB = fileSize / (1024 * 1024);

  // Set the maximum allowed size (5 MB)
  const maxAllowedSize = 5;

  // Check if file size exceeds limit
  if (fileSizeInMB > maxAllowedSize) {
    alert("File size exceeds 5MB limit!");
    return false; // File size is too large
  }

  return true; // File size is valid
};

export const isValidEmail = (value) => {
  if (typeof value === "string") {
    return (
      value
        .trim()
        .match(
          /^([a-zA-Z0-9_+\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
        ) !== null
    );
  }
  return false;
};

export function toLowerCaseKeys(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(toLowerCaseKeys);
  }

  return Object.keys(obj).reduce((acc, key) => {
    const lowerKey = key.toLowerCase();
    acc[lowerKey] = toLowerCaseKeys(obj[key]);
    return acc;
  }, {});
}
