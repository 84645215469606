import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HOROSCOPE_SIGN } from "../../../redux/actions";
import { listHoroscopeApi } from "../../../routes/apiRoutes";



export const getHoroscopeSignMiddleWare = createAsyncThunk(
    HOROSCOPE_SIGN,
    async (payload, { rejectWithValue }) => {
      try {
        const { data } = await axios.post(listHoroscopeApi , payload);
        return data
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );