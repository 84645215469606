import { loaderReducers } from "./store/commonReducer";
import { questionReducers } from "../pages/Prompt/store/promptReducer";
import { cityReducers } from "../pages/Prompt/store/promptReducer";
import {
  loginReducers,
  loginUserReducers,
} from "../../src/components/topbar/store/loginReducer";
import {
  getBindGameReducers,
  getFortuneCookiesReducers,
  getBookOfLoveReducers,
  getAstroGenieReducers,
  getColorTherapyReducers,
  getCrystalBallReducers,
} from "../pages/games/store/gamesReducer";
import {
  astrocureReviewReducers,
  astrocureTestimonialReducers,
  astroRatingReducers,
  getExperienceReducers,
  getExpertiseReducers,
  getLanguageReducers,
} from "../pages/consult-astrology/store/consultastrologyReducer";
import { predictionVideoReducers } from "../pages/video/store/videoReducer";
import {
  celebrityReducers,
  compactibilityReducers,
} from "../pages/compactibility/store/compactibilityReducer";
import { horoscopeReducers } from "../pages/horoscope/store/horoscopeReducer";
import { profileReducers } from "../components/topbar/profile/store/profileReducer";

export const reducers = {
  loaderReducers,
  questionReducers,
  cityReducers,
  getExperienceReducers,
  getExpertiseReducers,
  getLanguageReducers,
  loginReducers,
  getBindGameReducers,
  getFortuneCookiesReducers,
  getBookOfLoveReducers,
  getAstroGenieReducers,
  getColorTherapyReducers,
  getCrystalBallReducers,
  astrocureTestimonialReducers,
  astrocureReviewReducers,
  predictionVideoReducers,
  compactibilityReducers,
  celebrityReducers,
  horoscopeReducers,
  profileReducers,
  loginUserReducers,
  astroRatingReducers,
};
