import { fetchUrl } from "../utils/apiConfig";

export const loginApi = fetchUrl("tempuser");
export const verifyOTPApi = fetchUrl("tempuser-verify");
export const userDetailsApi = fetchUrl("user");
export const questionTopicApi = fetchUrl("bind-life-question-topic");
export const questionListApi = fetchUrl("bind-life-question-by-topic/");
export const addGuestQsApi = fetchUrl("guest-user-life-question");
export const getCityApi = fetchUrl("bind-city");
export const availableAstrologers = fetchUrl(
  "astro-counselling/list-available-astrologers"
);

export const bindGameApi = fetchUrl(`bind-game`);
export const fortuneCookiesApi = (id) => fetchUrl(`fortune-cookies/${id}`);
export const bookOfLoveApi = (id) => fetchUrl(`book-of-love/${id}`);
export const astroGenieApi = (id) => fetchUrl(`astro-genie/${id}`);
export const colorTherapyApi = (id) => fetchUrl(`color-therapy/${id}`);
export const crystalBallApi = (id) => fetchUrl(`crystal-ball/${id}`);

export const getExperienceApi = fetchUrl("bind-experience");
export const getExpertiseApi = fetchUrl("bind-expertise");
export const getLanguageApi = fetchUrl("bind-language");
export const getbStateApi = fetchUrl("bind-state");

export const listAvailableAstrologersApi = fetchUrl(
  `astro-counselling/list-available-astrologers`
);

export const listTestimonialAstrologerApi = fetchUrl("ceo-feedback");
export const listReviewAstrologerApi = fetchUrl(
  "astro-counselling/list-reviews/"
);
export const helpCountApi = fetchUrl("astro-counselling/help-count");
export const astroRatingApi = fetchUrl("astro-counselling/rating");

export const listPredictionVideoApi = fetchUrl("video/filter");
export const videoLikeApi = fetchUrl("video/like/");
export const videoUnlikeApi = fetchUrl("video/unlike/");
export const videoCaterogyListApi = fetchUrl("bind-video-category");
export const videoCountApi = fetchUrl("video/count/");

export const zodiacApi = fetchUrl("bind-zodiac");
export const loveCompatibilityApi = fetchUrl("compatibility/");
export const bondCompatibilityApi = fetchUrl("compatibility-check");
export const listCelebrityApi = fetchUrl("zodiac-profile");
export const createProfileApi = fetchUrl("zodiac-data");

export const userDeleteAccountApi = fetchUrl(`account-delete`);
export const listHoroscopeApi = fetchUrl("daily-sun-single");
export const bindBlogCategoryApi = fetchUrl(`bind-blog-category`);
export const websiteBlogApi = fetchUrl(`website-blog-get-with-filter`);

export const websiteBlogGetByIdApi = (id) =>
  fetchUrl(`website-blog-by-route/${id}`);

export const addKidsApi = fetchUrl("kids");
export const kidsWalletPaymentConfirmApi = fetchUrl(
  "kids-wallet-payment-confirm"
);

export const walletRequestApi = fetchUrl(`wallet/request`);
export const walletPaymentRequestApi = fetchUrl(`wallet/web-payment-request`);
export const getBalanceAvalableApi = (id) =>
  fetchUrl(`kids/get-balance-avalable/${id}`);
export const profileDetailsApi = fetchUrl("user");
export const updateMobileNoApi = fetchUrl("tempuser");
export const verifyMobileNoApi = fetchUrl("tempuser-verify");
export const updateEmailIDApi = fetchUrl("tempuser-email");
export const verifyEmailIDApi = fetchUrl("tempuser-email-verify");

export const saveEmailIDApi = fetchUrl("auth/email-update");
export const saveMobileNoApi = fetchUrl("auth/mobile-update");

export const fileUploadApi = fetchUrl(`upload-file`);

export const getNotificationApi = (userId) =>
  fetchUrl(`latest-user-notification/${userId}`);

export const getWalletListApi = fetchUrl(`bind-plan`);
export const userDeleteApi = (id) => fetchUrl(`user/${id}`);

export const contactApi = fetchUrl("contact-us");

export const phonepeStatusCheckApi = fetchUrl(`callback-status-check`);

export const termsPrivacyApi = fetchUrl(`settings/terms_privacy`);
export const aboutUsApi = fetchUrl(`settings/about_us`);
export const astrologerTermsPrivacyApi = fetchUrl(
  `settings/astrologer_terms_privacy`
);

export const socialLinksApi = fetchUrl(`bind-social-links`);
