import { createSlice } from "@reduxjs/toolkit";

const loaderInitialState = {
  isLoader: false,
};

export const loaderReducer = createSlice({
  name: "loader",
  initialState: loaderInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.isLoader = action.payload;
    },
  },
});

export const { setLoader } = loaderReducer.actions;

export const loaderReducers = loaderReducer.reducer;
