import axios from "axios";

export const useInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        // toast.error(error.response.data?.message, {
        //   position: "top-center",
        // });
        // localStorage.clear();
      } else {
        // if (error.response.data?.message) {
        //   console.log("____ssss");
        //   toast.error(error.response.data?.message, {
        //     position: "top-center",
        //   });
        // }
      }

      return Promise.reject(error);
    }
  );
};
