import { createSlice } from "@reduxjs/toolkit"
import { getHoroscopeSignMiddleWare } from "./horoscopeMiddleware"



const initialState = {
    data : [],
    isLoading : false
}

const horoscopeReducer = createSlice({
    name : "horoscope",
    initialState,
    reducers: {},
    extraReducers : (builder) => {
      builder.addCase(getHoroscopeSignMiddleWare.pending, (state) => {
        state.isLoading = true
      });
      builder.addCase(getHoroscopeSignMiddleWare.fulfilled, (state, {payload}) => {
        state.isLoading = false
        state.data = payload
      });
      builder.addCase(getHoroscopeSignMiddleWare.rejected, (state) => {
        state.isLoading = false
        
      });
    }
})


export const horoscopeReducers = horoscopeReducer.reducer