import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  LIST_PREDICTION_VIDEO,
  LIST_PREDICTION_VIDEO_MOBILE,
  VIDEO_CATEGORY_LIST,
  VIDEO_COUNT,
  VIDEO_LIKE,
  VIDEO_UNLIKE,
} from "../../../redux/actions";
import {
  listPredictionVideoApi,
  videoCaterogyListApi,
  videoCountApi,
  videoLikeApi,
  videoUnlikeApi,
} from "../../../routes/apiRoutes";

export const listPredictionVideoMiddleWare = createAsyncThunk(
  LIST_PREDICTION_VIDEO,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(listPredictionVideoApi, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const listPredictionVideoMobileMiddleWare = createAsyncThunk(
  LIST_PREDICTION_VIDEO_MOBILE,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(listPredictionVideoApi, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const videoLikeMiddleWare = createAsyncThunk(
  VIDEO_LIKE,
  async ({ userID, videoID }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${videoLikeApi}${videoID}/${userID}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const unLikeVideoMiddleWare = createAsyncThunk(
  VIDEO_UNLIKE,
  async ({ userID, videoID }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${videoUnlikeApi}${videoID}/${userID}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const videoCategoryMiddleWare = createAsyncThunk(
  VIDEO_CATEGORY_LIST,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(videoCaterogyListApi);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const videoCountMiddleWare = createAsyncThunk(
  VIDEO_COUNT,
  async ({ userID, videoID }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${videoCountApi}${videoID}/${userID}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
