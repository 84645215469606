import { createSlice } from "@reduxjs/toolkit";
import {
  getAstroGenieMiddleWare,
  getBindGameMiddleWare,
  getBookOfLoveMiddleWare,
  getColorTherapyMiddleWare,
  getCrystalBallMiddleWare,
  getFortuneCookiesMiddleWare,
} from "./gamesMiddleware";

const getBindGameInitialState = {
  isLoading: false,
};

const getBindGameReducer = createSlice({
  name: "get_Bind_Game",
  initialState: getBindGameInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBindGameMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBindGameMiddleWare.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(getBindGameMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const getFortuneCookiesInitialState = {
  isLoading: false,
};

const getFortuneCookiesReducer = createSlice({
  name: "getFortuneCookie",
  initialState: getFortuneCookiesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFortuneCookiesMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getFortuneCookiesMiddleWare.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      }
    );
    builder.addCase(getFortuneCookiesMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const getBookOfLoveInitialState = {
  isLoading: false,
};

const getBookOfLoveReducer = createSlice({
  name: "getBookOfLove",
  initialState: getBookOfLoveInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBookOfLoveMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBookOfLoveMiddleWare.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(getBookOfLoveMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const getAstroGenieInitialState = {
  isLoading: false,
};

const getAstroGenieReducer = createSlice({
  name: "getAstroGenie",
  initialState: getAstroGenieInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAstroGenieMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAstroGenieMiddleWare.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(getAstroGenieMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const getColorTherapyInitialState = {
  isLoading: false,
};

const getColorTherapyReducer = createSlice({
  name: "getColorTherapy",
  initialState: getColorTherapyInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getColorTherapyMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getColorTherapyMiddleWare.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      }
    );
    builder.addCase(getColorTherapyMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const getCrystalBallInitialState = {
  isLoading: false,
};

const getCrystalBallReducer = createSlice({
  name: "getCrystalBall",
  initialState: getCrystalBallInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCrystalBallMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getCrystalBallMiddleWare.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      }
    );
    builder.addCase(getCrystalBallMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const getBindGameReducers = getBindGameReducer.reducer;
export const getFortuneCookiesReducers = getFortuneCookiesReducer.reducer;
export const getBookOfLoveReducers = getBookOfLoveReducer.reducer;
export const getAstroGenieReducers = getAstroGenieReducer.reducer;
export const getColorTherapyReducers = getColorTherapyReducer.reducer;
export const getCrystalBallReducers = getCrystalBallReducer.reducer;
