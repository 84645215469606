import { createSlice } from "@reduxjs/toolkit";
import {
  astroRatingMiddleWare,
  getExperienceMiddleWare,
  getExpertiseMiddleWare,
  getLanguageMiddleWare,
  getReviewByAstrologersMiddleWare,
  testimonialAstrologersMiddleWare,
} from "./consultastrologyMiddleware";

const getExperienceInitialState = {
  isLoading: false,
};

const getExperienceReducer = createSlice({
  name: "get_experience_all",
  initialState: getExperienceInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getExperienceMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getExperienceMiddleWare.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(getExperienceMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const getExpertiseInitialState = {
  isLoading: false,
};

const getExpertiseReducer = createSlice({
  name: "get_Expertise_all",
  initialState: getExpertiseInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getExpertiseMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getExpertiseMiddleWare.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(getExpertiseMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const getLanguageInitialState = {
  isLoading: false,
};

const getLanguageReducer = createSlice({
  name: "get_Language_all",
  initialState: getLanguageInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLanguageMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLanguageMiddleWare.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(getLanguageMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const astrocureTestimonialState = {
  data: [],
  isLoading: false,
};

const astrocureTestimonialReducer = createSlice({
  name: "get_astrocure-testimonial",
  initialState: astrocureTestimonialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(testimonialAstrologersMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      testimonialAstrologersMiddleWare.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      }
    );
    builder.addCase(testimonialAstrologersMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const astrocureReviewState = {
  data: [],
  isLoading: false,
};

const astrocureReviewReducer = createSlice({
  name: "astrocure-details-review",
  initialState: astrocureReviewState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReviewByAstrologersMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getReviewByAstrologersMiddleWare.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      }
    );
    builder.addCase(getReviewByAstrologersMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const astroRatingState = {
  data: [],
  isLoading: false,
};

const astroRatingReducer = createSlice({
  name: "astro-rating",
  initialState: astroRatingState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(astroRatingMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(astroRatingMiddleWare.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(astroRatingMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const getExperienceReducers = getExperienceReducer.reducer;
export const getExpertiseReducers = getExpertiseReducer.reducer;
export const getLanguageReducers = getLanguageReducer.reducer;
export const astrocureTestimonialReducers = astrocureTestimonialReducer.reducer;
export const astrocureReviewReducers = astrocureReviewReducer.reducer;
export const astroRatingReducers = astroRatingReducer.reducer;
