import { createSlice } from "@reduxjs/toolkit";
import { getCityMiddleWare, questionListMiddleWare, questionTopicMiddleWare } from "./promptMiddleware";


const initialState = {
    isLoading: false,
    data: [],
    topicList : [],
  };

const questionReducer = createSlice({
    name :"ask_question",
    initialState,
    reducers:{},
    extraReducers : (builder) => {
        builder.addCase(questionTopicMiddleWare.pending,(state) => {
            state.isLoading = true
        });
        builder.addCase(questionTopicMiddleWare.fulfilled,(state,{payload}) => {
            state.data = payload;
            state.isLoading = false
        });
        builder.addCase(questionTopicMiddleWare.rejected,(state) => {
            state.isLoading = false
        })

        builder.addCase(questionListMiddleWare.fulfilled,(state,{payload}) => {
        state.topicList = payload
        })
    }
})

const cityInitialState = {
    data : [],
    isLoading: false
}
const cityReducer = createSlice({
    name :"get_Cities",
    initialState : cityInitialState,
    reducers:{},
    extraReducers : (builder) => {
        builder.addCase(getCityMiddleWare.pending,(state) => {
            state.isLoading = true
        });
        builder.addCase(getCityMiddleWare.fulfilled,(state,{payload}) => {
            state.isLoading = false;
            state.data = payload
        });
        builder.addCase(getCityMiddleWare.rejected,(state) => {
            state.isLoading = false
        });
    }
})


export const questionReducers = questionReducer.reducer;
export const cityReducers = cityReducer.reducer;