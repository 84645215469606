import { createSlice } from "@reduxjs/toolkit";
import { celebritylistMiddleware, createPartnerProfileMiddleware, createProfileMiddleware, listZodiacMiddleware } from "./compactibilityMiddleware";

const initialState = {
  isLoading: false,
  data: [],
  zoiacList: [],
  createProfile : [],
  partnerProfile: []
};

const compactibilityReducer = createSlice({
  name: "compactibility",
  initialState,
  reducers: {
    resetPartnerProfile : (state) => {
      state.createProfile = [];
      state.partnerProfile = []
    },
    resetProfile : (state) => {
      state.createProfile = []
    },
    
    // resetCeleb : (state) => {
    //   state.zoiacList = []
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(listZodiacMiddleware.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listZodiacMiddleware.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.zoiacList = payload
    });
    builder.addCase(listZodiacMiddleware.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProfileMiddleware.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.createProfile = payload;
      
    });
    builder.addCase(createPartnerProfileMiddleware.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.partnerProfile = payload
    });
  },
});
const celebrityInitialState = {
  isLoading : false,
  data : []
}


const celebrityReducer = createSlice({
  name : "celebrity",
  initialState :  celebrityInitialState,
  reducers:{},
  extraReducers: (builder) => {
      builder.addCase(celebritylistMiddleware.pending,(state) => {
          state.isLoading = true
      });
      builder.addCase(celebritylistMiddleware.fulfilled,(state,{payload}) => {
          state.isLoading = false
          state.data = payload
      });
      builder.addCase(celebritylistMiddleware.rejected,(state) => {
          state.isLoading = false
      });
  }
})

export const { resetPartnerProfile , resetProfile ,  resetCeleb} = compactibilityReducer.actions;

export const celebrityReducers = celebrityReducer.reducer

export const compactibilityReducers = compactibilityReducer.reducer;
