import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  astroRatingApi,
  getExperienceApi,
  getExpertiseApi,
  getLanguageApi,
  helpCountApi,
  listAvailableAstrologersApi,
  listReviewAstrologerApi,
  listTestimonialAstrologerApi,
} from "../../../routes/apiRoutes";
import {
  ASTRO_RATING,
  GET_EXPERIENCE,
  GET_EXPERTISE,
  GET_LANGUAGE,
  HELP_COUNT,
  LIST_AVAILABLE_ASTROLOGERS,
  LIST_REVIEW_ASTROLOGERS,
  LIST_TESTIMONIAL_ASTROLOGERS,
} from "../../../redux/actions";

export const getExperienceMiddleWare = createAsyncThunk(
  GET_EXPERIENCE,
  async () => {
    try {
      const { data } = await axios.get(getExperienceApi);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getExpertiseMiddleWare = createAsyncThunk(
  GET_EXPERTISE,
  async () => {
    try {
      const { data } = await axios.get(getExpertiseApi);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getLanguageMiddleWare = createAsyncThunk(
  GET_LANGUAGE,
  async () => {
    try {
      const { data } = await axios.get(getLanguageApi);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const listAvailableAstrologersMiddleWare = createAsyncThunk(
  LIST_AVAILABLE_ASTROLOGERS,
  async ({ payload }) => {
    try {
      const { data } = await axios.post(listAvailableAstrologersApi, payload);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const testimonialAstrologersMiddleWare = createAsyncThunk(
  LIST_TESTIMONIAL_ASTROLOGERS,
  async () => {
    try {
      const { data } = await axios.get(listTestimonialAstrologerApi);
      return data;
    } catch (error) {
      return error;
    }
  }
);
export const getReviewByAstrologersMiddleWare = createAsyncThunk(
  LIST_REVIEW_ASTROLOGERS,
  async (astroID) => {
    try {
      const { data } = await axios.get(`${listReviewAstrologerApi}${astroID}`);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const helpCountMiddleWare = createAsyncThunk(
  HELP_COUNT,
  async ({ feedback_id, helpCount, userId }) => {
    try {
      const { data } = await axios.put(helpCountApi, {
        feedbackId: feedback_id,
        helpCount,
        userId,
      });
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const astroRatingMiddleWare = createAsyncThunk(
  ASTRO_RATING,
  async ({ astroglerId }) => {
    try {
      const { data } = await axios.post(astroRatingApi, {
        astroglerId,
      });
      return data;
    } catch (error) {
      return error;
    }
  }
);
