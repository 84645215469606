import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { reducers } from "./mainreducers";

const resetStoreActionType = "main/resetStore";

const combinedReducer = combineReducers(reducers);

export const rootReducer = (state, action) => {
  if (action.type === resetStoreActionType) {
    state = undefined; // eslint-disable-line
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const resetStore = () => {
  store.dispatch({ type: resetStoreActionType });
};

export default store;
