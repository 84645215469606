export const LOGIN_USER_MOBILE = "login/LOGIN_USER_MOBILE";
export const LOGIN_VERIFY_MOBILE = "login/LOGIN_VERIFY_MOBILE";
export const LOGIN_USER_DETAILS = "login/LOGIN_USER_DETAILS";

export const QUESTION_TOPIC = "question/QUESTION_TOPIC";
export const QUESTION_LIST = "question/QUESTION_LIST";
export const ADD_GUEST_QUESTION = "question/ADD_GUEST_QUESTION";
export const GET_CITY = "question/GET_CITY";

// games
export const GET_BIND_GAMEA = "game/GET_BIND_GAMEA";
export const GET_FORTUNE_COOKIES = "game/GET_FORTUNE_COOKIES";
export const GET_BOOK_OF_LOVE = "game/GET_BOOK_OF_LOVE";
export const GET_ASTRO_GENIE = "game/GET_ASTRO_GENIE";
export const GET_COLOR_THERAPY = "game/GET_COLOR_THERAPY";
export const GET_CRYSTAL_BALL = "game/GET_CRYSTAL_BALL";

// consultastrology
export const GET_EXPERIENCE = "consultastrology/GET_EXPERIENCE";
export const GET_EXPERTISE = "consultastrology/GET_EXPERTISE";
export const GET_LANGUAGE = "consultastrology/GET_LANGUAGE";
export const LIST_AVAILABLE_ASTROLOGERS =
  "consultastrology/LIST_AVAILABLE_ASTROLOGERS";
export const LIST_TESTIMONIAL_ASTROLOGERS =
  "consultastrology/LIST_TESTIMONIAL_ASTROLOGERS";
export const LIST_REVIEW_ASTROLOGERS =
  "consultastrology/LIST_REVIEW_ASTROLOGERS";
export const HELP_COUNT = "consultastrology/HELP_COUNT";
export const ASTRO_RATING = "consultastrology/ASTRO_RATING";

export const LIST_PREDICTION_VIDEO = "video/LIST_PREDICTION_VIDEO";
export const LIST_PREDICTION_VIDEO_MOBILE = "video/LIST_PREDICTION_VIDEO";
export const VIDEO_LIKE = "video/VIDEO_LIKE";
export const VIDEO_UNLIKE = "video/VIDEO_UNLIKE";
export const VIDEO_CATEGORY_LIST = "video/VIDEO_CATEGORY_LIST";
export const VIDEO_COUNT = "video/VIDEO_COUNT";

// compactibility
export const LIST_ZODIAC = "compactibility/LIST_ZODIAC";
export const ZODIAC_SPECIFIC_ID = "compactibility/GET_ZODIAC_ID";
export const CHECK_LOVE_COMPACTIBILITY =
  "compactibility/CHECK_LOVE_COMPACTIBILITY";
export const CHECK_BOND_COMPACTIBILITY =
  "compactibility/CHECK_BOND_COMPACTIBILITY";
export const LIST_CELEBRITY = "compactibility/LIST_CELEBRITY";
export const CREATE_PROFILE = "compactibility/CREATE_PROFILE";
export const CREATE_PROFILE_PARTNER = "compactibility/CREATE_PROFILE_PARTNER";

export const USER_DELETE_ACCOUNT = "footer/USER_DELETE_ACCOUNT";

// Horoscope

export const HOROSCOPE_SIGN = "horoscope/HOROSCOPE_SIGN";

export const BIND_BLOG_CATEGORY = "blogs/BIND_BLOG_CATEGORY";
export const WEBSITE_BLOG = "blogs/WEBSITE_BLOG";
export const WEBSITE_BLOG_GET_BY_ID = "blogs/WEBSITE_BLOG_GET_BY_ID";

export const ADD_KIDS = "kundali/ADD_KIDS";
export const GET_BALANCE_AVALABLE = "kundali/GET_BALANCE_AVALABLE";
export const KIDS_WALLET_PAYMENT_CONFIRM =
  "kundali/KIDS_WALLET_PAYMENT_CONFIRM";
export const WALLET_REQUEST = "kundali/WALLET_REQUEST";
export const WALLET_PAYMENT_REQUEST = "kundali/WALLET_PAYMENT_REQUEST";

export const GET_PROFILE_DETAILS = "profile/GET_PROFILE_DETAILS";
export const UPDATE_PROFILE_DETAILS = "profile/UPDATE_PROFILE_DETAILS";
export const UPDATE_MOBILE_NUMBER = "profile/UPDATE_MOBILE_NUMBER";
export const VERIFY_MOBILE_NUMBER = "profile/VERIFY_MOBILE_NUMBER";
export const VERIFY_EMAIL_ID = "profile/VERIFY_EMAIL_ID";
export const UPDATE_EMAIL_ID = "profile/UPDATE_EMAIL_ID";

export const SAVE_EMAIL_ID = "profile/SAVE_EMAIL_ID";
export const SAVE_MOBILE_NUMBER = "profile/SAVE_MOBILE_NUMBER";
export const FILE_UPLOAD = "profile/FILE_UPLOAD";
export const GET_NOTIFICATION = "profile/GET_NOTIFICATION";
export const GET_WALLET = "profile/GET_WALLET";
export const USER_DELETE = "profile/USER_DELETE";
export const PHONEPE_STATUS_CHECK = "profile/PHONEPE_STATUS_CHECK";

export const CONTACT = "contact/CONTACT";

export const TERMS_PRIVACY = "contact/TERMS_PRIVACY";
export const ABOUT_US = "contact/ABOUT_US";
export const ASTROLOGER_TERMS_PRIVACY = "contact/ASTROLOGER_TERMS_PRIVACY";
export const SOCIAL_LINKS = "contact/SOCIAL_LINKS";
