import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CHECK_BOND_COMPACTIBILITY,
  CHECK_LOVE_COMPACTIBILITY,
  CREATE_PROFILE,
  CREATE_PROFILE_PARTNER,
  LIST_CELEBRITY,
  LIST_ZODIAC,
  ZODIAC_SPECIFIC_ID,
} from "../../../redux/actions";
import axios from "axios";
import {
  bondCompatibilityApi,
  createProfileApi,
  listCelebrityApi,
  loveCompatibilityApi,
  zodiacApi,
} from "../../../routes/apiRoutes";

export const listZodiacMiddleware = createAsyncThunk(
  LIST_ZODIAC,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(zodiacApi);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getZodiacByIDMiddleware = createAsyncThunk(
  ZODIAC_SPECIFIC_ID,
  async (zodiacID, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${zodiacApi}/${zodiacID}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkLoveCompatibilityMiddleware = createAsyncThunk(
  CHECK_LOVE_COMPACTIBILITY,
  async ({ zodiac_1, zodiac_2 }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${loveCompatibilityApi}${zodiac_1}/${zodiac_2}`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const celebritylistMiddleware = createAsyncThunk(
  LIST_CELEBRITY,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(listCelebrityApi);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createProfileMiddleware = createAsyncThunk(
  CREATE_PROFILE,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(createProfileApi, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const createPartnerProfileMiddleware = createAsyncThunk(
  CREATE_PROFILE_PARTNER,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(createProfileApi, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkBondCompatibilityMiddleware = createAsyncThunk(
  CHECK_BOND_COMPACTIBILITY,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(bondCompatibilityApi, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
