import { createSlice } from "@reduxjs/toolkit";
import { loginUserMiddleWare } from "./loginMiddleware";

const initialState = {
  isLoading: false,
  data: [],
};

const loginReducer = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: () => {},
});

const loginInitialState = {
  isLoading: false,
};

const loginUserReducer = createSlice({
  name: "login_user",
  initialState: loginInitialState,
  reducers: {
    setLoginUser: (state, action) => {
      state.data = { ...state.data, mobileNumber: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUserMiddleWare.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginUserMiddleWare.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(loginUserMiddleWare.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setLoginUser } = loginUserReducer.actions;

export const loginUserReducers = loginUserReducer.reducer;
export const loginReducers = loginReducer.reducer;
