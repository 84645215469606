import React, { useState, useEffect } from "react";
import "./scrollButton.scss";
import ArrowGif from "../../assets/home-page-icons/arrow.gif";

const ScrollButton = () => {
  const [scrollDistance, setScrollDistance] = useState(1);

  const scrollToBottom = () => {
    const windowHeight = window.innerHeight;
    const maxScrollDistance =
      document.documentElement.scrollHeight - windowHeight;

    if (scrollDistance >= maxScrollDistance) {
      setScrollDistance(0); // Reset the scroll distance to zero
    } else {
      const newScrollDistance = scrollDistance + windowHeight;
      window.scrollTo({
        top: newScrollDistance,
        behavior: "smooth",
      });
      setScrollDistance(newScrollDistance);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollDistance = window.scrollY;
      const maxScrollDistance =
        document.documentElement.scrollHeight - window.innerHeight;

      if (currentScrollDistance >= maxScrollDistance) {
        setScrollDistance(0); // Reset the scroll distance to zero
      } else {
        setScrollDistance(currentScrollDistance);
      }
    };

    // Add a scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <img
      src={ArrowGif}
      alt="ArrowGif"
      className={`scroll-button ${scrollDistance === 0 ? "hidden" : ""}`}
      onClick={scrollToBottom}
    />
  );
};

export default ScrollButton;
