import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  LOGIN_USER_DETAILS,
  LOGIN_USER_MOBILE,
  LOGIN_VERIFY_MOBILE,
} from "../../../redux/actions";
import {
  loginApi,
  userDetailsApi,
  verifyOTPApi,
} from "../../../routes/apiRoutes";

export const loginUserMiddleWare = createAsyncThunk(
  LOGIN_USER_MOBILE,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(loginApi, payload);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyOTPMiddleWare = createAsyncThunk(
  LOGIN_VERIFY_MOBILE,
  async (payload) => {
    try {
      const { data } = await axios.post(verifyOTPApi, payload);
      return data;
    } catch (error) {
      return error;
    }
  }
);
export const submitUserMiddleWare = createAsyncThunk(
  LOGIN_USER_DETAILS,
  async (payload) => {
    try {
      const { data } = await axios.post(userDetailsApi, payload);
      return data;
    } catch (error) {
      return error;
    }
  }
);
