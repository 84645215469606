import React, { Suspense } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import Loader from "./packages/Loader/Loader";
import ScrollToTop from "./hooks/scrollToTop";
import ScrollButton from "./components/utils/scrollButton";

const HomeScreen = React.lazy(() => import("./pages/home/HomeScreen"));
const HoroscopeScreen = React.lazy(() =>
  import("./pages/horoscope/HoroscopeScreen")
);
const CompactibilityScreen = React.lazy(() =>
  import("./pages/compactibility/CompactibilityScreen")
);
const GamesScreen = React.lazy(() => import("./pages/games/GamesScreen"));
const AboutUsScreen = React.lazy(() =>
  import("./pages/about-us/AboutUsScreen")
);
const EditProfileScreen = React.lazy(() =>
  import("./components/topbar/profile/EditProfileScreen")
);
const WalletScreen = React.lazy(() =>
  import("./components/topbar/profile/WalletScreen")
);
const NotificationScreen = React.lazy(() =>
  import("./components/topbar/profile/NotificationScreen")
);
const DeleteAccountScreen = React.lazy(() =>
  import("./components/topbar/profile/DeleteAccountScreen")
);
const PaymentStatusCheckScreen = React.lazy(() =>
  import("./components/topbar/profile/PaymentStatusCheckScreen")
);

const UserDelete = React.lazy(() => import("./pages/user-delete/UserDelete"));

const TermsAndConditionAstrologer = React.lazy(() =>
  import("./pages/terms-and-privacy/terms-condition-astrologer")
);
const KundliScreen = React.lazy(() => import("./pages/kundali/KundliScreen"));
const PaymentSuccessScreen = React.lazy(() =>
  import("./pages/kundali/PaymentSuccessScreen")
);

const TermsAndCondition = React.lazy(() =>
  import("./pages/terms-and-privacy/terms-condition")
);

const PrivacyPolicy = React.lazy(() =>
  import("./pages/terms-and-privacy/privacy-policy")
);
const VideoScreen = React.lazy(() => import("./pages/video/VideoScreen"));
const ContactUs = React.lazy(() => import("./pages/contact-us/contact"));
const ConsultAstrologyScreen = React.lazy(() =>
  import("./pages/consult-astrology/ConsultAstrologyScreen")
);
const AstrologerDetails = React.lazy(() =>
  import("./pages/consult-astrology/astrologer-details")
);
const Blogs = React.lazy(() => import("./pages/blogs/Blogs"));
const BlogPost = React.lazy(() => import("./pages/blogs/BlogPost"));
const PromptSection = React.lazy(() => import("./pages/Prompt/PromptSection"));

const mapStateToProps = (state) => {
  const { loaderReducers } = state;
  return {
    isLoader: loaderReducers.isLoader,
  };
};

const App = ({ isLoader }) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  return (
    <>
      <Router>
        <Suspense fallback={<Loader />}>
          <div className="App">
            <ToastContainer
              bodyStyle={{ fontFamily: "Poppins-Regular" }}
              position="top-center"
            />
            {isLoader && <Loader />}
            <ScrollToTop />
            {window.location.pathname !== "/games" && <ScrollButton />}

            <Routes>
              <Route path="/kundali" element={<KundliScreen />} />
              {isLoggedIn && (
                <>
                  <Route
                    path="/kundali/payment/:status"
                    element={<PaymentSuccessScreen />}
                  />
                  <Route path="/edit-profile" element={<EditProfileScreen />} />
                  <Route path="/wallet" element={<WalletScreen />} />
                  <Route
                    path="/notifications"
                    element={<NotificationScreen />}
                  />
                  <Route
                    path="/delete-account"
                    element={<DeleteAccountScreen />}
                  />
                  <Route
                    path="/payment-status-check"
                    element={<PaymentStatusCheckScreen />}
                  />
                </>
              )}

              <Route
                path="/compactibility"
                element={<CompactibilityScreen />}
              />
              <Route path="/horoscope" element={<HoroscopeScreen />} />
              <Route path="/games" element={<GamesScreen />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndCondition />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              <Route
                path="/terms-and-conditions-astrologer"
                element={
                  <TermsAndConditionAstrologer title={"Terms And Conditions"} />
                }
              />
              <Route
                path="/privacy-policy-astrologer"
                element={
                  <TermsAndConditionAstrologer
                    isPrivacy
                    title={"Privacy Policies"}
                  />
                }
              />
              <Route path="/videos" element={<VideoScreen />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route
                path="/consult-astrology"
                element={<ConsultAstrologyScreen />}
              />
              <Route
                path="/astrologer-details"
                element={<AstrologerDetails />}
              />
              <Route path="/about" element={<AboutUsScreen />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blogs/:blogId" element={<BlogPost />} />
              <Route path="/" element={<HomeScreen />} />
              <Route path="/ask-question" element={<PromptSection />} />
              <Route path="/user-delete" element={<UserDelete />} />
            </Routes>
          </div>
        </Suspense>
      </Router>
    </>
  );
};

export default connect(mapStateToProps)(App);
